@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
  }
  
  @font-face {
    font-family: 'Montserrat-Bold';
    src: url('../fonts/Montserrat/Montserrat-Bold.ttf');
  }
  
  @font-face {
    font-family: 'NunitoSans';
    src: url('../fonts/Nunito_Sans/NunitoSans-Regular.ttf');
  }
  
  @font-face {
    font-family: 'NunitoSans-Bold';
    src: url('../fonts/Nunito_Sans/NunitoSans-Bold.ttf');
  }
  
  @font-face {
    font-family: 'opendyslexic';
    src: url('../fonts/open_dyslexic/OpenDyslexic-Regular.otf');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'opendyslexic';
    src: url('../fonts/open_dyslexic/OpenDyslexic-Italic.otf');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'opendyslexic';
    src: url('../fonts/open_dyslexic/OpenDyslexic-Bold.otf');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'opendyslexic';
    src: url('../fonts/open_dyslexic/OpenDyslexic-BoldItalic.otf');
    font-weight: bold;
    font-style: italic;
  }