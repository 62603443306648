@import "colors";
@import "fonts";
@import "~bootstrap/scss/bootstrap";
@import "~material-icons/iconfont/material-icons";

/* margin negative start */
.mt-n1 {
    margin-top: -0.5rem !important;
}

.mt-n2 {
    margin-top: -1.5rem !important;
}

.mt-n3 {
    margin-top: -3rem !important;
}

.mb-n1 {
    margin-bottom: -0.5rem !important;
}

.mb-n2 {
    margin-bottom: -1.5rem !important;
}

.mb-n3 {
    margin-bottom: -3rem !important;
}

.ms-n1 {
    margin-left: -0.5rem !important;
}

.ms-n2 {
    margin-left: -1.5rem !important;
}

.ms-n3 {
    margin-left: -3rem !important;
}

.me-n1 {
    margin-right: -0.5rem !important;
}

.me-n2 {
    margin-right: -1.5rem !important;
}

.me-n3 {
    margin-right: -3rem !important;
}

/* margin negative end*/


/*************/
/** Element **/
/*************/
* {
    user-select: none !important;
}

body {
    font-family: 'Montserrat', sans-serif !important;
    color: var(--dark);
    width: 100dvw;
    height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background: none !important;

    &::-webkit-scrollbar {
        width: 0em;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'NunitoSans-Bold', sans-serif !important;
}

:focus {
    outline: unset !important;
}

.dropdown-toggle {
    &::after {
        display: none !important;
    }
}

.pointer {
    cursor: pointer !important;
}

strong,
.font-weight-bold {
    font-weight: 700 !important;
}

.bolder {
    font-weight: bolder;
}

p {
    line-height: 1.3rem !important;
}

.nunito {
    font-family: 'NunitoSans', sans-serif !important;
}

.nunito-bold {
    font-family: 'NunitoSans-Bold', sans-serif !important;
}

.opacity-0 {
    opacity: 0;
}

.filter-gray {
    filter: grayscale(1);
}

.text-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
}

.text-xxs {
    font-size: 0.65rem;
    line-height: 0.65rem;
}

.text-xs {
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.text-sm {
    font-size: 0.85rem;
    line-height: 0.85rem;
}

.text-md {
    font-size: 0.95rem;
    line-height: 0.95rem;
}

.text-lg {
    font-size: 1.2rem;
    line-height: 1.2rem;
}

.text-xlg {
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.text-xl {
    font-size: 1.8rem;
    line-height: 1.8rem;
}

.text-xxl {
    font-size: 2rem;
    line-height: 2rem;
}


/* Colors - Backgrounds - Buttons */
.text-primary {
    color: #C12B18 !important;
}

.text-light-primary {
    color: #D99183 !important;
}

.text-secondary {
    color: #E2C32D !important;
}

.text-light-secondary {
    color: #F2C834;
}

.text-dark {
    color: #292521 !important;
}

.text-white {
    color: #fff !important
}

.bg-none {
    background: none !important;
}

.bg-white-trans {
    background: rgba(255, 255, 255, 0.75);
}

.bg-primary {
    background: #C12B18 !important;
}

.bg-primary-gradient {
    background: linear-gradient(90deg, #A21712 0%, #E93712 100%);
}

.bg-primary-gradient-vertical {
    background: linear-gradient(#EB915A 0%, #D6200B 50%, #D6200B 100%);
}

.bg-secondary {
    background: #E2972D !important;
}

.bg-error-page {
    background: linear-gradient(#FEDAA3 0%, #E2972D 100%);
}

.bg-secondary-gradient {
    background: linear-gradient(90deg, #A35806 0%, #E49000 100%);
}

.bg-light-secondary-gradient {
    background: linear-gradient(90deg, #E9AA30 0%, #F2C834 100%);
}

.bg-light-secondary-gradient-vertical {
    background: linear-gradient(#F7CF83 0%, #DBA91B 50%, #DBA91B 100%);
}

.btn-primary {
    background: linear-gradient(90deg, #981312 0%, #EA3712 25%, #981312 50%, #EA3712 100%);
}

.btn-secondary {
    background: linear-gradient(90deg, #E1932D 0%, #F2C834 50%, #E1932D 100%);
}

.btn-dark-secondary {
    background: linear-gradient(90deg, #6C3B00 0%, #9E5A00 50%, #6C3B00 100%);
}

.btn-secondary,
.btn-primary,
.btn-dark-secondary {
    letter-spacing: 1px;
    border: none;
    border-radius: 50px;
    transition: background-position 0.4s;
    background-size: 200% 100%;

    &:hover {
        background-position: 100% 0%;
    }
}

/* Colors - Backgrounds - Buttons */


.min-height-50 {
    min-height: 50dvh !important;
}

.min-height-75 {
    min-height: 75dvh !important;
}

.min-height-100 {
    min-height: 100dvh !important;
}

.z-index-n100 {
    z-index: -100 !important;
}

.z-index-n1 {
    z-index: -1 !important;
}

.z-index-1 {
    z-index: 1 !important;
}

.z-index-10 {
    z-index: 10 !important;
}

.z-index-100 {
    z-index: 100 !important;
}


.card-perso {
    border-radius: 35px;
    margin: auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.card-question {
    width: 50%;

    @include media-breakpoint-down(lg) {
        width: 60% !important;
    }

    @include media-breakpoint-down(md) {
        width: 95% !important;
    }
}

.card-video {
    background: #E9D1D1;
}


/**********/
/* Header */
/**********/
header {
    position: fixed;
    top: 0;
    margin-bottom: 70px;
    width: 100vw;
    transition: all 0.3s ease-in-out;
    z-index: 99999 !important;

    &.active {
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
    }

    nav {
        height: 70px;

        a {
            text-decoration: none !important;

            .logo {
                height: 70px;
                width: auto;
            }
        }

        .nav-left {
            .nav-item {
                .nav-link {
                    font-size: 0.95rem;
                }

                .bottom-line {
                    height: 2px;
                    border-radius: 100px;
                }

                .bottom-line-anim {
                    width: 0% !important;
                    transition: width 0.2s;
                }

                &:hover {
                    .bottom-line-anim {
                        width: 50% !important;
                    }
                }
            }
        }

        .nav-right {
            .nav-item {
                .nav-link {
                    .nav-link-icon {
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }

        .nav-menu {
            .nav-item {
                .nav-link {
                    .menu-icon {
                        font-size: 24px;
                        line-height: 24px;
                    }
                }

            }
        }

        .flag {
            width: 25px;
            transition: transform 0.1s;

            &:hover {
                transform: scale(1.25);
            }
        }
    }
}

/**********************/
/** Right menu modal **/
/**********************/

.right-menu-modal {
    .item-close {
        line-height: 1.8rem;

        .material-icons {
            font-size: 1.8rem;
        }
    }

    .item-menu {
        line-height: 1.5rem;
        font-size: 1.5rem;

        .icon {
            width: 70px;
        }
    }

    .divider {
        height: 2px;
        margin-left: 70px;
        border-radius: 2px;
        width: 50%;
    }
}

/*****************/
/** Modal Right **/
/*****************/

.modal-right {
    padding: 0 !important;
}

.modal-right .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;

    @media (min-width: 650px) {
        width: 50%;
        left: 50%;
    }
}

.modal-right .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-right .modal-body {
    overflow-y: auto;
}

/**********************/
/** Right menu modal **/
/**********************/

.right-menu-modal {
    .media {
        top: 5%;
        right: 5%;

        .item-close {
            line-height: 1.8rem;

            .material-icons {
                font-size: 1.8rem;
            }
        }
    }

    .item-menu {
        line-height: 1.5rem;
        font-size: 1.5rem;

        .icon {
            width: 70px;
        }
    }

    .divider {
        height: 2px;
        margin-left: 70px;
        border-radius: 2px;
        width: 50%;
    }

    .submenu a {
        margin-left: 4.7rem !important;
    }
}

/************/
/** Footer **/
/************/

footer {
    margin-top: 7rem !important;
    box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.25);
    margin-top: 0 !important;
    z-index: 9999 !important;

    a {
        text-decoration: none !important;
    }

    .legal-links {
        line-height: 1.5rem;
    }

    .logo {
        width: 100%;
        max-width: 200px;
    }

    .social-links {
        img {
            width: 32px;
        }
    }

    .flag-footer {
        width: 30px !important;
        transition: transform 0.1s ease;

        &:hover {
            transform: scale(1.2);
        }
    }

    @include media-breakpoint-down(md) {
        .columns {
            .title {
                border-bottom: 2px solid var(--extramedium);
            }
        }
    }
}

section {
    overflow-x: clip;
}

/**************/
/** Question **/
/**************/
section#question {
    .background-image {
        background-image: url(/public/assets/img/bg1.svg);
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    img.left-lantern {
        height: 75%;
        top: 100%;
        left: 5%;
    }

    img.right-lantern {
        height: 75%;
        top: 100%;
        right: 5%;
    }

    img.left-fireworks {
        width: 25%;
        top: 0;
        left: 5%;
    }

    img.right-fireworks {
        width: 25%;
        top: -5%;
        right: 5%;
    }

    img.mascotte-chapeau {
        height: 100%;
        bottom: -30%;
        right: -10%;

        @include media-breakpoint-down(lg) {
            right: 0%;
            bottom: -60%;
        }
    }
}

/************/
/**  Index **/
/************/

/* Headline */
#bg0 {
    background-image: url(/public/assets/img/bg0.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

section#index-headline {

    img.pig2 {
        height: 40%;
        bottom: 0%;
        left: 15%;

        @include media-breakpoint-down(lg) {
            height: 30%;
            left: 10%;
        }
    }

    img.suit-boy {
        height: 60%;
        bottom: -5%;
        right: 8%;

        @include media-breakpoint-down(lg) {
            height: 40%;
        }
    }
}

/* You-are */
section#index-you-are {

    .div-icon-user {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        top: -10%;
        left: -5%;

        .icon-user {
            font-size: 100px;
        }
    }

    img.left-flowers {
        height: 100%;
        left: 0%
    }

    img.right-flowers {
        height: 120%;
        right: -5%
    }

    img.pig-suit {
        width: 25%;
        right: -15%;
        bottom: -20%;
    }
}

/* About */
section#index-about {
    background: linear-gradient(#28181B 0%, #30191B 100%);

    img.lantern {
        height: 150%;
        top: -80%;
        right: 35%;

        @media (min-width: 2000px) {
            right: 75%;
        }

        @include media-breakpoint-down(lg) {
            width: 40%;
            top: -75%;
            right: 25%;
        }

        @include media-breakpoint-down(md) {
            width: 20%;
            top: -40%;
            right: 0;
        }
    }

    img.flowers-branch {
        height: 150%;
        left: -20%;
        top: -25%;

        @media (min-width:1400px) {
            left: -10%;
        }

        @include media-breakpoint-down(lg) {
            left: -25%;
        }
    }

    img.flowers-branch-mobile {
        width: 80%;
        left: -20%;
    }

    img.flowers-branch-lanterns {
        height: 250%;
        right: -20%;
        top: -40%;
        z-index: 1;

        @media (min-width:1400px) {
            right: -10%;
        }

        @include media-breakpoint-down(lg) {
            right: -25%;
        }
    }

    img.flowers-branch-lanterns-mobile {
        width: 80%;
        right: -20%;
    }
}

/* Satisfaction */
section#index-satisfaction {
    background: linear-gradient(#30191B 0%, #3A1E11 100%);

    span {
        font-size: 5rem;
    }

    img.suit2 {
        height: 250px;
    }

    img.left-flowers {
        height: 200%;
        left: 0;
        top: 0;
    }

    img.right-flowers {
        rotate: 180deg;
        height: 200%;
        right: 0;
        top: -20%;
    }
}

/* Satisfaction  Mobile */
section#index-satisfaction-mobile {
    background: linear-gradient(#341A19 0%, #3A1E11 100%);

    span {
        font-size: 3rem;
    }

    img.suit2 {
        height: 250px;
    }

    img.flowers {
        height: 100%;
        top: 0;
    }
}

/* Certification */
section#index-certification {
    background: linear-gradient(#3A1E11 0%, #422109 100%);

    p {
        padding-bottom: 0 !important;
    }

    img.piece {
        height: 75px;
    }

    img.piece-mobile {
        height: 150px;
    }

    img.statue {
        height: 125%;
        right: 0%;
        top: -15%;

        @media (min-width:992px) and (max-width:1229px) {
            right: -15%;
        }

        @media (min-width: 2000px) {
            right: 20%;
        }
    }
}

/* Review */
section#index-review {
    background: linear-gradient(#422109 0%, #482801 100%);

    img.temple {
        width: 150%;
    }

    img.flowers1 {
        rotate: 90deg;
        height: 100%;
        top: 0;
        right: 15%;
    }

    img.flowers2 {
        height: 100%;
        top: 0;
        left: 0;
    }

    .content {
        top: 15%;
        height: 70%;
        max-width: 2500px;

        @include media-breakpoint-down(lg) {
            height: 65%;
            top: 17.5%;
        }

        .review-photo {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;

            img {
                margin: 0 auto;
                height: 100%;
                width: auto;
            }
        }

        .review-content {
            margin-top: 12%;
            overflow: hidden;
        }

        .review-author {
            margin-bottom: 2%;
        }

        .review-content-mobile {
            margin-top: 20%;
            overflow: hidden;
        }

        button.carousel-control-prev,
        button.carousel-control-next {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            opacity: 1 !important;
            margin-top: 40%;

            @media (max-width: 991px) {
                position: absolute;
                top: 50%;
                width: 30px;
                height: 30px;

                span {
                    width: 1rem !important;
                    height: 1rem !important;
                }
            }
        }

        span {
            width: 1.5rem !important;
            height: 1.5rem !important;
        }
    }

    button.carousel-control-prev,
    button.carousel-control-next {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        opacity: 1 !important;
    }

    .btn-more-mobile {
        bottom: 0 !important;
    }
}

.modal-review {
    background: rgba(0, 0, 0, .3);

    .modal-dialog {
        border: none;

        .modal-content {
            border: none;
            background: none;
            height: 90dvh;

            img.volumen {
                height: 100%;

                @media (max-width: 768px) {
                    height: 90%;
                }
            }

            .review-photo {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    height: 100%;
                    max-width: 100%;
                }
            }

            button.carousel-control-prev,
            button.carousel-control-next {
                position: absolute;
                top: calc(50% - 30px);
                width: 40px;
                height: 40px;
                border-radius: 50%;
                opacity: 1 !important;

                span {
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                }
            }
        }

        .btn-exit {
            background: none;
            border: none;
            top: 40px;
            right: 40px;
            transition: all 0.3s ease-in-out;
            font-size: 30px;

            &:hover {
                rotate: 90deg;
            }
        }
    }
}

/* Question */
section#index-question {

    .background-image {
        background-image: url(/public/assets/img/bg2.svg);
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
    }

    img.bg-2 {
        top: -50%;
    }
}


/****************/
/** Individual **/
/****************/
section#individual-headline {
    background: linear-gradient(#FEDAA3 0%, #F2C37C 100%);
    padding-top: 120px;

    img.clouds {
        top: 0%;
    }

    img.temple {
        min-width: 125%;

        @include media-breakpoint-down(md) {
            min-width: 165%;
        }

        @include media-breakpoint-down(sm) {
            min-width: 185%;
        }
    }

    .content {
        top: 48%;
        height: 30%;

        h4 {
            font-size: 3dvw;
            line-height: 2.2rem !important;
        }

        p {
            font-size: 1.5dvw;
            line-height: 1.5rem !important;
            margin-top: 2%;
            margin-bottom: 3%;
        }

        @media (min-width:1200px) {
            h4 {
                font-size: 2.2rem;
                line-height: 2.2rem !important;
            }

            p {
                font-size: 1.2rem;
                line-height: 1.8rem !important;
            }
        }

        @include media-breakpoint-down(lg) {
            h4 {
                font-size: 2.5dvw;
                line-height: 1.8rem !important;
            }

            p {
                font-size: 1.6dvw;
                line-height: 1.3rem !important;
            }
        }

        @include media-breakpoint-down(md) {
            h4 {
                font-size: 2.7dvw;
                line-height: 1.5rem !important;
            }

            p {
                font-size: 1.6dvw;
                line-height: 1.1rem !important;
                margin-top: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            h4 {
                font-size: 2.9dvw;
                line-height: 1rem !important;
            }

            p {
                font-size: 2dvw;
                line-height: 0.8rem !important;
            }
        }

        @media (max-width: 410px) {
            h4 {
                font-size: 2.9dvw;
                line-height: 0.7rem !important;
            }

            p {
                font-size: 2dvw;
                line-height: 0.5rem !important;
            }
        }

        img.tiger {
            height: 150%;
            bottom: -60%;
            right: -35%;

            @include media-breakpoint-down(xl) {
                height: 125%;
                right: -30%;
            }

            @include media-breakpoint-down(lg) {
                height: 100%;
                bottom: -70%;
                right: -25%;
            }

            @include media-breakpoint-down(sm) {
                height: 75%;
                right: -20%;
            }
        }
    }
}

/* Why */
section#individual-why {
    background: linear-gradient(#F2C37C 0%, #ECB867 100%);

    img.lapin {
        height: 200px;
        bottom: -20%;
        right: -15%;

        @include media-breakpoint-down(lg) {
            height: 150px;
            bottom: -30%;
            right: 0;
        }

        @include media-breakpoint-down(md) {
            height: 100px;
            bottom: -15%;
            right: 0;
        }
    }
}

/* Satisfaction */
section#individual-satisfaction {
    background: linear-gradient(#ECB867 0%, #E7AD54 100%);

    span {
        font-size: 5rem;
    }

}

/* Review */
section#individual-review {
    background: linear-gradient(#E7AD54 0%, #DC9830 100%);

    img.temple {
        width: 150%;
    }

    img.pieces1 {
        height: 90%;
        top: -40%;
        left: 0%;
    }

    img.pieces2 {
        height: 90%;
        top: -40%;
        right: 0%;
    }

    .content {
        top: 15%;
        height: 70%;
        max-width: 2500px;

        @include media-breakpoint-down(lg) {
            height: 65%;
            top: 17.5%;
        }

        .review-photo {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;

            img {
                margin: 0 auto;
                height: 100%;
                width: auto;
            }
        }

        .review-content {
            margin-top: 12%;
            overflow: hidden;
        }

        .review-author {
            margin-bottom: 2%;
        }

        .review-content-mobile {
            margin-top: 20%;
            overflow: hidden;
        }

        button.carousel-control-prev,
        button.carousel-control-next {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            opacity: 1 !important;
            margin-top: 40%;

            @media (max-width: 991px) {
                position: absolute;
                top: 50%;
                width: 30px;
                height: 30px;

                span {
                    width: 1rem !important;
                    height: 1rem !important;
                }
            }
        }

        span {
            width: 1.5rem !important;
            height: 1.5rem !important;
        }
    }

    button.carousel-control-prev,
    button.carousel-control-next {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        opacity: 1 !important;
    }

    .btn-more-mobile {
        bottom: 0 !important;
    }
}

/* Cpf */
section#individual-cpf {
    background: linear-gradient(#DC9830 0%, #DA9327 100%);

    img.cpf {
        height: 300px;
        bottom: -10%;
        left: 10%;

        @include media-breakpoint-down(xxl) {
            height: 250px;
            left: 0;
            bottom: 0;
        }

        @include media-breakpoint-down(xl) {
            height: 150px;
            left: 15%;
            bottom: 40%;
        }

        @include media-breakpoint-down(lg) {
            height: 200px;
            left: 0;
            bottom: 0;
        }
    }
}

/***********/
/** About **/
/***********/
section#about-headline {
    background: linear-gradient(#FDD9A2 0%, #F2C37C 100%);
    padding-top: 120px;

    img.clouds {
        top: 0%;
    }

    img.souris {
        height: 250px;
        bottom: -15%;
        right: -23%;

        @include media-breakpoint-down(xxl) {
            height: 225px;
        }

        @include media-breakpoint-down(xl) {
            height: 200px;
        }

        @include media-breakpoint-down(lg) {
            height: 175px;
            bottom: -30%;
            right: -5%;
        }

        @include media-breakpoint-down(md) {
            height: 150px;
            bottom: -20%;
            right: -10%;
        }
    }
}

/* Vigdis */
section#about-vigdis {
    background: linear-gradient(#F2C37C 0%, #E3A547 100%);

    img.temple {
        @media (min-width: 992px) {
            width: 2500px;
        }

        @media (max-width:992px) {
            width: 2000px;
        }

        @media (max-width:675px) {
            width: 1700px;
        }

        @media (max-width:520px) {
            width: 1500px;
        }
    }

    .content {
        @media (min-width: 992px) {
            top: 10%;
            height: 67.5%;
        }

        @media (max-width:992px) {
            top: 11%;
            height: 69%;
        }

        @media (max-width:675px) {
            top: 12%;
            height: 68%;
        }

        @media (max-width:520px) {
            top: 12.5%;
            height: 67%;
        }

        .review-photo {
            border-radius: 50%;
            overflow: hidden;

            @media (min-width: 992px) {
                width: 200px;
                height: 200px;
            }

            @media (max-width: 992px) {
                width: 160px;
                height: 160px;
            }

            @media (max-width: 675px) {
                width: 135px;
                height: 135px;
            }

            @media (max-width: 520px) {
                width: 120px;
                height: 120px;
            }

            img {
                margin: 0 auto;
                height: 100%;
                width: auto;
            }
        }

        .review-content {
            @media (min-width: 992px) {
                width: 600px;

                h4 {
                    font-size: 1.5rem;
                    line-height: 1.5rem !important;
                }

                p {
                    font-size: 0.8rem;
                    line-height: 1rem !important;
                }
            }

            @media (max-width: 992px) {
                width: 500px;

                h4 {
                    font-size: 1.3rem;
                    line-height: 1.3rem !important;
                }

                p {
                    font-size: 0.7rem;
                    line-height: 0.9rem !important;
                }
            }

            @media (max-width: 675px) {
                width: 400px;

                h4 {
                    font-size: 1.1rem;
                    line-height: 1.1rem !important;
                }

                p {
                    font-size: 0.65rem;
                    line-height: 0.7rem !important;
                }
            }

            @media (max-width: 520px) {
                width: 325px;

                h4 {
                    font-size: 1rem;
                    line-height: 1rem !important;
                }

                p {
                    font-size: 0.55rem;
                    line-height: 0.55rem !important;
                }
            }
        }
    }

    img.left-flowers {
        rotate: 180deg;
        top: 0%;
        left: 0%;
        height: 50%;
    }

    img.right-flowers {
        rotate: 180deg;
        top: 0%;
        right: 0%;
        height: 100%;
    }
}

/* Mascotte */
section#about-mascotte {
    background: linear-gradient(#E3A547 0%, #DA9327 100%);

    img.suit-boy {
        height: 250px;
        top: -10%;
        left: -8%;

        @include media-breakpoint-down(lg) {
            height: 200px;
            top: 10%;
            left: -5%;
        }
    }
}

/*************/
/** Contact **/
/*************/
section#contact-form {
    background: linear-gradient(#FEDAA3 0%, #DA9327 100%);
    padding-top: 120px;

    img.clouds {
        top: 0%;
    }

    img.left-flowers {
        height: 100%;
        left: 0;
        bottom: 0;
    }

    img.right-flowers {
        height: 100%;
        top: 0;
        right: 0;
    }

    img.tigre-boy {
        height: 35%;
        bottom: 10%;
        left: 5%;

        @media (max-width: 1400px) {
            height: 30%;
        }

        @media (max-width: 992px) {
            height: 25%;
            left: 2%;
        }
    }

    img.tigre-girl {
        height: 35%;
        bottom: 10%;
        right: 0%;

        @media (max-width: 1400px) {
            height: 30%;
        }

        @media (max-width: 992px) {
            height: 25%;
        }
    }

    img.temple {
        @media (min-width:1400px) {
            width: 3000px;
        }

        @media (max-width: 1400px) {
            width: 2500px;
        }

        @media (max-width: 992px) {
            width: 2000px;
        }

        @media (max-width: 600px) {
            width: 1250px;
        }
    }

    .content {
        @media (min-width: 1400px) {
            top: 44%;
            width: 700px;
        }

        @media (max-width: 1400px) {
            top: 42%;
            width: 550px;
        }

        @media (max-width: 992px) {
            top: 40%;
            width: 475px;
        }

        @media (max-width: 768px) {
            top: 42%;
            width: 500px;
        }

        @media (max-width: 600px) {
            top: 39%;
            width: 325px;
        }


        .form-group {
            @media (min-width: 1400px) {
                font-size: 0.85rem;
                line-height: 0.85rem;
            }

            @media (max-width: 1400px) {
                font-size: 0.75rem;
                line-height: 0.3rem;
            }

            @media (max-width: 768px) {
                font-size: 0.75rem;
                line-height: 1rem;
            }

            @media (max-width: 600px) {
                font-size: 0.5rem;
                line-height: 0.5rem;
            }

            label {
                margin-bottom: 0.5rem;
            }
        }

        .form-www {
            border-radius: 25px !important;
            padding: 10px 20px;
            background-color: white !important;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            border: none;
            display: block;
            width: 100%;
            line-height: 1.5;

            @include media-breakpoint-down(lg) {
                padding: 5px 10px;
            }
        }
    }
}


/*******************/
/** Bibliographie **/
/*******************/
section#bibliographie {
    background: linear-gradient(#FDD9A2 0%, #DA9327 100%);
    padding-top: 120px;

    //Category List
    .category-list {

        .category {
            cursor: pointer;

            .category-img {
                height: 100px;
            }
        }
    }

    .modal-dialog {
        .modal-content {
            border-radius: 50px !important;

            button.btn-close {
                top: 30px;
                right: 30px;
                font-size: 30px;
                transition: all 0.3s ease-in-out;

                &:hover {
                    rotate: 90deg;
                }
            }
        }
    }

    img.shelf {
        margin-top: 20%;
        margin-bottom: -5%;
    }

    .bibliotheque {
        @media (min-width:1400px) {
            width: 1200px;
            height: 320px;
        }

        @media (max-width: 1400px) {
            width: 80%;
            height: 28%;
        }

        @media (max-width: 992px) {
            width: 95%;
            height: 28%;
        }

        @media (max-width: 768px) {
            width: 100%;
        }

        @media (max-width: 500px) {
            width: 105%;
            height: 25%;
        }

        .etage-1 {
            @media (min-width: 992px) {
                margin-bottom: 8%;
            }

            @media (max-width: 992px) {
                margin-bottom: 7.5%;
            }

            @media (max-width: 500px) {
                margin-bottom: 6.5%;
            }

            img.book:hover {
                transform: translateY(-20px);
            }
        }

        img.book {
            transition: transform 0.3s;
            cursor: pointer;

            @media (min-width: 1400px) {
                width: 140px;
            }

            @media (max-width: 1400px) {
                width: 90%;
            }
        }

        img.book-rotate:hover {
            transform: rotateY(20deg);
        }
    }
}


/*************/
/** Prénoms **/
/*************/
section#prenoms {
    background: linear-gradient(#FDD9A2 0%, #DA9327 100%);
    padding-top: 120px;

    /* searchbar */
    .prenoms-header-searchbar {
        position: relative;
        background-color: white;
        border-radius: 25px;
        width: fit-content;
        color: var(--trophy-silver);
        vertical-align: center;
        display: flex;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        z-index: 100;

        input {
            border: none;
            color: var(--trophy-silver);

            &::placeholder {
                color: var(--trophy-silver);
            }
        }

        .btn-searchbar-firstname {
            color: white;
            border-radius: 25px;
            font-weight: bold;
        }
    }

    .scrollable-menu {
        overflow-x: hidden;
        padding: 60px 20px 10px 20px;
        margin: -45px 0 0 0 !important;
        width: 362px;
        height: auto;
        max-height: 300px;
        border: none !important;
        border-radius: 22px;
        box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
        z-index: 1 !important;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            overflow-y: scroll;
            width: 100%;
            max-height: 140px;

            &::-webkit-scrollbar {
                width: 18px;
                background-color: #fff;
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                height: 40px;
                background: linear-gradient(90deg, #A21712 0%, #E93712 100%);
            }
        }
    }

    /* searchbar */

    /* start volumen */
    .open-volumen {
        @media (min-width:500px) {
            width: 600px;
        }

        @media (max-width:500px) {
            width: 450px;
        }
    }

    .parchemin {
        @media (min-width:500px) {
            width: 300px;
            height: 350px;
            margin-top: 120px;
        }

        @media (max-width:500px) {
            width: 250px;
            height: 250px;
            margin-top: 100px;

            .text-xxl {
                font-size: 1.5rem !important;
                line-height: 1.5rem !important;
            }

            .text-xlg {
                font-size: 1.2rem !important;
                line-height: 1.2rem !important
            }
        }

        span {
            border-bottom: 2px solid #28181B;
        }
    }

    .prenoms {
        @media (max-width:500px) {
            .text-xlg {
                font-size: 1.2rem !important;
                line-height: 1.2rem !important
            }
        }

        span {
            border-bottom: 2px solid #C12B3C;
        }

        ul {
            padding-left: 0;
            list-style: none !important;

            li a {
                text-decoration: none !important;
            }
        }
    }

    /* end volumen */

    /* start book */
    .open-book {
        @media (min-width:1400px) {
            width: 1400px;
        }

        @media (max-width:1400px) {
            width: 1000px;
        }

        @media (max-width:992px) {
            width: 800px;
        }

        @media (max-width:768px) {
            width: 650px;
        }

        @media (max-width:500px) {
            width: 500px;
        }
    }

    .page {
        @media (min-width:1400px) {
            width: 1400px;
            height: 450px;
            margin-top: 130px;
        }

        @media (max-width:1400px) {
            width: 1000px;
            height: 330px;
            margin-top: 75px;
        }

        @media (max-width:992px) {
            width: 650px;
            height: 275px;
            margin-top: 45px;
        }

        @media (max-width:768px) {
            width: 520px;
            height: 230px;
            margin-top: 30px;
        }

        @media (max-width:600px) {
            width: 480px;
            height: 240px;
            margin-top: 25px;
        }

        @media (max-width:500px) {
            width: 350px;
            height: 190px;
            margin-top: 10px;
        }

        .next_link,
        .prev_link {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-top: 40%;
            opacity: 1 !important;
            text-align: center;

            span {
                width: 1.5rem !important;
                height: 1.5rem !important;
                min-height: 95%;
                line-height: 100%;
            }

        }

        ul#prenomsPager {
            li {
                padding: 0.2rem;

                a {
                    text-decoration: none;
                }
            }
        }

        .panel-title {
            margin: 0.2rem !important;
        }

        .title-book {
            @media (max-width:768px) {
                font-size: 1rem;
                line-height: 1rem;
            }

            @media (max-width:600px) {
                font-size: 0.9rem;
                line-height: 0.9rem;
            }

            @media (max-width:500px) {
                font-size: 0.6rem;
                line-height: 0.6rem;
            }
        }

        .line-book {
            @media (max-width:768px) {
                display: flex;
                justify-content: space-around;
                font-size: 0.8rem;
                line-height: 0.8rem;
            }

            @media (max-width:600px) {
                font-size: 0.7rem;
                line-height: 0.7rem;
            }

            @media (max-width:500px) {
                font-size: 0.5rem;
                line-height: 0.5rem;
            }
        }
    }

    /* end book */

    span.desole {
        font-size: 5rem;
    }
}

/*****************/
/** Dictionnary **/
/*****************/
section#dictionnary {
    background: linear-gradient(#FDD9A2 0%, #DA9327 100%);
    padding-top: 120px;

    .dictionnary-header-searchbar {
        position: relative;
        background-color: white;
        border-radius: 25px;
        width: fit-content;
        color: var(--trophy-silver);
        vertical-align: center;
        display: flex;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        z-index: 100;

        input {
            border: none;
            color: var(--trophy-silver);

            &::placeholder {
                color: var(--trophy-silver);
            }
        }

        .btn-searchbar {
            color: white;
            border-radius: 25px;
            font-weight: bold;
        }
    }

    .scrollable-menu {
        overflow-x: hidden;
        padding: 60px 20px 10px 20px;
        margin: -45px 0 0 0 !important;
        width: 362px;
        height: auto;
        max-height: 300px;
        border: none !important;
        border-radius: 22px;
        box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
        z-index: 1 !important;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            overflow-y: scroll;
            width: 100%;
            max-height: 140px;

            &::-webkit-scrollbar {
                width: 18px;
                background-color: #fff;
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                height: 40px;
                background: linear-gradient(90deg, #A21712 0%, #E93712 100%);
            }
        }
    }

    .pancarte {
        @media (min-width:1400px) {
            width: 1400px;
        }

        @media (max-width:1400px) {
            width: 1000px;
        }

        @media (max-width:768px) {
            width: 650px;
        }

        @media (max-width:500px) {
            width: 500px;
        }
    }

    .dictionnary-content {
        @media (min-width:1400px) {
            width: 1000px;
            height: 520px;
            margin-top: 250px;
        }

        @media (max-width:1400px) {
            width: 700px;
            height: 390px;
            margin-top: 160px;
        }

        @media (max-width:768px) {
            width: 500px;
            height: 270px;
            margin-top: 80px;
        }

        @media (max-width:500px) {
            width: 350px;
            height: 220px;
            margin-top: 50px;
        }
    }

    .panel-title {
        margin: 0;
    }

    ul#dictionnaryPager {
        li {
            padding: 0.2rem;

            a {
                color: #28181B;
                text-decoration: none;
            }
        }
    }

    span.desole {
        font-size: 5rem;
    }

}

/*********/
/** FAQ **/
/*********/
section#faq {
    background: linear-gradient(#FDD9A2 0%, #DA9327 100%);
    padding-top: 120px;

    img.confettis {
        max-width: 1500px;
    }

    .faq-header-searchbar {
        background-color: white;
        border-radius: 25px;
        width: fit-content;
        color: var(--trophy-silver);
        vertical-align: center;
        display: flex;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

        input {
            border: none;
            color: var(--trophy-silver);

            &::placeholder {
                color: var(--trophy-silver);
            }
        }

        .btn-searchbar {
            color: white;
            border-radius: 25px;
            font-weight: bold;
        }
    }

    //Category List
    .category-list {

        .category {
            cursor: pointer;

            .category-img {
                height: 80px;
            }
        }
    }

    .faq {

        .faq-border-bottom {
            border-bottom: 1px solid var(--light);
        }

        .faq-group {
            text-align: left;

            a {
                font-size: 20px;
                display: block;
                text-decoration: none;
            }

            .faq-body {
                color: var(--darklight);
                display: block;
            }
        }

        .more-less {
            float: right;
        }
    }

    .lapin-lantern {
        height: 250px;
        bottom: 0;
        right: 0;
    }
}

/**************************/
/** Not found Error page **/
/**************************/

.error-not-found {
    .error {
        max-width: 300px;
    }
}

//Cookies
#cookieConsent {
    background-color: #dfe6e9;
    text-align: center;
    display: none;
    position: fixed;
    z-index: 65000;
    bottom: 0px;
    width: 100%;
    font-size: 14px;
}

#cookieConsent button.cookieAccept {
    color: white;
    border: none;
    border-radius: 0.2em;
    margin: 0.5em;
    padding: 0.2em 0.5em 0.2em 0.5em;
}

.note-editor {
    display: none !important;
}

#article-headline {
    .chevron {
        max-width: 8px;
    }
    .article-headline-img {
        .article-headline-img-mobile {
            display: none;
        }
    }

    @media (max-width: 768px) {
        .article-headline-img {
            .article-headline-img-mobile {
                display: block;
            }
        }
    }
}

.searchbar {
    background-color: white;
    border-radius: 25px;
    width: fit-content;
    vertical-align: center;
    display: flex;
    align-items: center;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);

    input {
        border: none;
        background: none;
    }

    button {
        padding: 8px 20px;
    }
}

/**********/
/** Blog **/
/**********/
#blog-index {
    .categories-listing {
        @include media-breakpoint-up(sm) {
            position: sticky;
            top: 100px;
            z-index: 1;
        }

        a {
            color: var(--dark);
        }
    }
}

#blog-article {
    .article-headline {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .back-button {
        top: 0;
        left: 5%;
    }
}

#blog-category, #blog-author, #blog-tag {
    .back-button {
        top: 0;
        left: 5px;
    }
}

#blog-all {
    .back-button {
        top: 0;
        left: 5%;
    }
}





/*** Media Queries ***/

.blog-card {
    overflow: hidden;
    max-width: 350px;
    height: 500px;

    .card-headline {
        width: 100%;
        height: 175px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
            transform: scale(1.05);
        }
    }

    .card-content {
        width: 100%;
        height: 325px;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .article-tags {
            display: flex;
            flex-wrap: wrap;
            align-items: start;
            gap: 5px;
        }

        .article-title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }

        .article-content {
            font-size: 15px;
            color: var(--dark);
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }

        .icon {
            width: 25px;
        }

        .article-link {
            display: flex;
            align-items: end;
        }
    }
}


.formation-card {
    overflow: hidden;
    width: 400px;
    height: 580px;

    .banner-img {
        height: 15em;

        svg {
            position: absolute;
            bottom: -1px;
        }
    }

    .formation-content {
        padding-left: 30px;
        padding-right: 30px;

        .flag {
            width: 25px;
        }

        .formation-details {
            .icon {
                max-width: 20px;
            }
        }
    }

    .formation-price {
        color: transparent;
        background-clip: text;
    }
}

#formation-headline {
    .flag {
        width: 25px;
    }

    .icon {
        max-width: 30px;
    }

    .formation-headline-img {
        position: relative;
        width: 100%;
        min-height: 60dvh;
        border-radius: 0 0 0 50%;
        overflow: hidden;
    }

    .formation-headline-img-mobile {
        position: relative;
        width: 100%;
        height: 35dvh;

        svg {
            position: absolute;
            left: 0;
            top: -2px;
            rotate: 180deg;
        }
    }
}

#formations {
    .dropdown-menu {
        a {
            text-decoration: none !important;

            &:last-child {
                border-bottom: none !important;
            }
        }
    }

    .dropdown-sort {
        .chevron {
            width: 10px;
        }
    }

    .flag {
        width: 20px;
    }

    .icon-filter {
        width: 15px;
    }

    .cross {
        width: 10px;
        rotate: 45deg;
        right: 8px;
    }

    .pagination-buttons {
        .page-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px !important;
            height: 30px !important;
            padding: 0 !important;
            border-radius: 50%;
            background: linear-gradient(180deg, #F6CEB7 -31.67%, #F35B04 79.07%);
            color: var(--white);
            border: none;
            user-select: none !important;
            cursor: default;
        }
        .page-btn.inactive {
            background: none;
            color: var(--secondary);
            cursor: pointer;
        }
    }
}



