$primary: #f05f40;
$secondary: #3d0000;
$success: #07B55B;
$danger: #E03128;
$warning: #FFB405;
$info: #097DE2;
$light: #C3CAD9;
$dark: #333333;

$theme-colors: (
        "fake-white": #F5F5F5,
        "xtremlight": #F3F3F9,
        "extralight": #E6E6E6,
        "extramedium": #444444,
        "title": #2E3333,
        "facebook" : #176CF1,
        "turquoise" : #3574a5,
        "medium" : #8e8e8e,
        "purple": #e3e4fc,
        "purple-shadow": #7b7eb5,
        "course-fr": #e5f2fc,
        "trophy-unknow" : #cd0000,
        "trophy-bronze" : #614E1A,
        "trophy-silver" : #DADADA,
        "trophy-gold" : #D79A10,
        "trophy-jade" : #85B83F,
        "bluesky" : #7FBDF2,
        "darklight" : #707070,
        "extradark" : #1C1C1C,
        "light-purple" : #a29bfe,
        "dark-purple" : #6c62e7,
        "secondary": #3d0000
);
